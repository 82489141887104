$base-font-size: 16px;

$white: rgb(255,255,255);
$black: rgb(0,0,0);

$main: rgb(228,221,23);
$spinnerColor: $main;
$spinnerSize: 30px;
$spinnerBackround: lighten($black, 10%);

.text-color-main {
    color: $main;
}