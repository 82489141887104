@import "~bootstrap/scss/bootstrap";

@import "assets/styles/fonts.scss";
@import "assets/styles/variables.scss";
@import "assets/styles/mixins.scss";

body {
    margin: 0;
    font-family: LinetoCircularProBook;
    color: $white;
    background-color: $black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

figure {
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.dashboard {
    width: 100%;
    height: 100%;
    position: relative;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ( 9 / 16 ) * 100%;
    }

    &--inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 5vh;
        height: 100%;
        box-sizing: border-box;
    }

    .header {
        &--brand-logo {
            img {
                width: 126px;
                max-height: 44px;
            }
        }

        &--date {
            @include fluid-type(13px, 16px);
            text-transform: uppercase;
        }

        &--tagline {
            font-family: LinetoCircularProBold;
            @include fluid-type(14px, 18px);
            text-transform: uppercase;
        }
    }

    .footer {
        margin-bottom: 5vh;

        &--brand-icon {
            img {
                width: 36px;
                max-height: 44px;
            }
        }

        &--tagline {
            text-transform: uppercase;
        }

        &--tagline,
        &--onboarded-users {
            @include fluid-type(13px, 16px);
        }
    }

    .stats {
        //
    }
}

.module {

    &--wrapper {
        position: relative;
        overflow: hidden;
        @include aspect-ratio(16,9);
    }

    &--inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: lighten($black, 10%);
    }

    &--caption {
        @include fluid-type(11px, 16px, 1300px);
    }

    &--data {
        font-family: LinetoCircularProMedium;
        white-space: nowrap;
        @include fluid-type(28px, 105px);
    }

    &--loading {
        //
    }
}

.spinner {
    position: absolute;
    top: calc(50% - #{$spinnerSize / 2});
    left: calc(50% - #{$spinnerSize / 2});
    width: $spinnerSize;
    height: $spinnerSize;
    animation: spinner 0.75s linear infinite;
  
    span {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    
    .spinner-inner-1 {
      background: linear-gradient(to right, rgba($spinnerColor,0) 0%,rgba($spinnerColor,0) 50%,$spinnerColor 51%);
    }
    
    .spinner-inner-2 {
      background: linear-gradient(to top, rgba($spinnerBackround,0) 0%,$spinnerBackround 100%);
    }
    
    .spinner-inner-3 {
      $offset: $spinnerSize * 0.1;
      top: $offset;
      left: $offset;
      width: $spinnerSize - ($offset * 2);
      height: $spinnerSize - ($offset * 2);
      background: $spinnerBackround;
    }
  }



  