@font-face {
    font-family: 'LinetoCircularProBook';
    src: local('LinetoCircularProBook'), url('../fonts/lineto-circular-pro-book.ttf') format('truetype');
}

@font-face {
    font-family: 'LinetoCircularProBold';
    src: local('LinetoCircularProBold'), url('../fonts/lineto-circular-pro-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'LinetoCircularProMedium';
    src: local('LinetoCircularProMedium'), url('../fonts/lineto-circular-pro-medium.ttf') format('truetype');
}